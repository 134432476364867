import React from "react";
import { Footer, Navbar } from "../components";
import ScrollTop from "../components/ScrollTop";

function Terms() {
  return (
    <div style={{position:"relative"}}>
         <div style={{
        position: 'fixed',
        display: 'flex',
        bottom: '25px',
        right: '0px',
        zIndex: 99,
      }}>

        <ScrollTop />
      </div>
      <section>
        <div className="container terms_header_section">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="terms_heading text-start">Terms & Conditions</h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mb-5 pt-4 pb-5">
          <div className="row">
            <div className="col-lg-12">
              <p className="pt-2 terms_responsibility">
                If the client cancels the project midway (for any reason), 2K
                Virtual World will not refund the money. Instead, we will
                provide the source code up to the point where we stopped
                working. If the project drop is due to issues on our end, 2K
                Virtual World takes responsibility.
              </p>
              <p className="pt-1 terms_responsibility">
                Initial estimates for time duration and cost are provided by 2K
                Virtual World based on the client's requirements at the
                beginning of the project. Any additional requirements introduced
                by the client during the project will incur extra charges and
                may involve another developer.
              </p>
              <p className="pt-1 terms_responsibility">
                2K Virtual World commits to meeting the client's requirements
                for both mobile and website development. The provided estimate
                covers development and delivery charges only. 2K Virtual World
                is not responsible for the renewal and maintenance of the
                project. Additional charges apply if the client requires ongoing
                services.
              </p>
              <p className="pt-1 terms_responsibility">
                Integration services for third-party APIs, such as SMS or email
                integration, will not be notified or renewed by 2K Virtual World
                once the project is handed over to the client.
              </p>
              <p className="pt-1 terms_responsibility">
                The client needs to provide a user retention email address for
                payment integration. After project completion, the client can
                reset the password for the provided email.
              </p>
              <p className="pt-1 terms_responsibility">
                The client must submit the required documents within the
                specified time slot; otherwise, project delays may occur.
              </p>
              <p className="pt-1 terms_responsibility">
                2K Virtual World will share a demo screen with the client.
              </p>
            </div>
          </div>
        </div>
      </section>
    
      <Footer />
    </div>
  );
}

export default Terms;
