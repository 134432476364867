import React from "react";
import Marquee from "react-fast-marquee";
export default function CustomerLogo() {
  return (
    <>
      <Marquee>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/biological.png"
            alt="Card"
            style={{ width: "50%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/dicekart.png"
            alt="Card"
            style={{ width: "50%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/dreamdigital.png"
            alt="Card"
            style={{ width: "50%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/limpus.png"
            alt="Card"
            style={{ width: "50%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/panayeri.png"
            alt="Card"
            style={{ width: "50%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/qdr.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/srilakshmi.png"
            alt="Card"
            style={{ width: "50%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/trust.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/techlogo/bookshopelogo.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/techlogo/detective.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/techlogo/ipffms.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/techlogo/ipfplLogo.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/techlogo/sifmLogo.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/techlogo/pg.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/techlogo/vinmar.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
        <div style={{ padding: "30px" }}>
          <img
            className="card-img img-fluid"
            src="./assets/techlogo/besttimber.png"
            alt="Card"
            style={{ width: "30%" }}
          />
        </div>
      
      </Marquee>
    </>
  );
}
