import React from "react";
import { Footer, Navbar } from "../components";
import ScrollTop from "../components/ScrollTop";

export default function CRM() {
  return (
    <div style={{position:"relative"}}>
         <div style={{
        position: 'fixed',
        display: 'flex',
        bottom: '25px',
        right: '0px',
        zIndex: 99,
      }}>

        <ScrollTop />
      </div>
      <section>
        <div className="container crm_heading_section">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="crm_heading_title">
                Customer Relationship <br /> Management
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-6 my-2">
              <h3 className="privacy_policy_heading_global_get">
                Customer relationship management
              </h3>
              <p className="customer_web-para">
                Customer Relationship Management (CRM) is a strategic strategy
                that businesses utilize to oversee and scrutinize customer
                interactions throughout the entire lifecycle. It involves
                utilizing technology to streamline processes, heighten customer
                satisfaction, and propel overall growth. By centralizing
                customer data and interactions, CRM systems empower
                organizations to gain a better understanding of their customers'
                needs, personalize interactions, and ultimately forge enduring
                relationships.of CRM
              </p>
            </div>

            <div className="col-lg-6">
              <img src="/assets/crmsec.jpg" style={{ width: "100%",borderRadius:'9px' }} />
            </div>
            <div className="col-lg-12">
              <h3 className="privacy_policy_heading_global_get mt-4">
                User Data Collection:
              </h3>
              <p className="customer_web-para">
                Gather and store relevant information about website visitors.
                This may include demographics, browsing behavior, preferences,
                and any other data that can help create a comprehensive user
                profile.
              </p>
              <h3 className="privacy_policy_heading_global_get">
                Personalization :
              </h3>
              <p className="customer_web-para">
                Use the collected data to personalize the content displayed on
                the website. This can involve recommending products, suggesting
                articles, or tailoring the user interface based on the user's
                past interactions.
              </p>
              <h3 className="privacy_policy_heading_global_get">
                Communication Channels:
              </h3>
              <p className="customer_web-para">
                Implement various communication channels such as chat support,
                email newsletters, or social media engagement to connect with
                users. Respond promptly to inquiries and provide helpful
                information.
              </p>
              <h3 className="privacy_policy_heading_global_get">
                Feedback and Surveys:
              </h3>
              <p className="customer_web-para">
                Encourage users to provide feedback on their experiences with
                the website. Conduct surveys to understand their satisfaction,
                identify areas for improvement, and gather insights into their
                preferences.
              </p>
            </div>
            <div className="col-lg-6 my-2">
              <h3 className="privacy_policy_heading_global_get">
                Customer Segmentation:
              </h3>
              <p className="customer_web-para">
                - Divide users into segments based on their behavior,
                preferences, or other criteria. This allows for targeted
                communication and content recommendations for each segment.
              </p>
              <h3 className="privacy_policy_heading_global_get">
                Integration with Customer Support:
              </h3>
              <p className="customer_web-para">
                Seamlessly enhance customer relationships with our integrated
                Customer Support solution. Streamline communication, resolve
                issues swiftly, and build lasting connections. Elevate your
                customer relationship management to new heights, ensuring
                satisfaction at every interaction. Experience the power of a
                unified platform for efficient support and stronger customer
                bonds. Empower your business with our customer-centric approach
                today!"
              </p>
            </div>
            <div className="col-lg-6 ">
              <img
                src="/assets/crmfrst.jpg"
                style={{ width: "100%",borderRadius:'9px' }}
              />
            </div>

            <div className="col-lg-12 my-1">
              <h3 className="privacy_policy_heading_global_get mt-4">
                Customer Loyalty Programs:
              </h3>
              <p className="customer_web-para">
                Unlock exclusive rewards and perks with our Customer Loyalty
                Program! Join today to enjoy personalized experiences, special
                discounts, and priority access. We value your loyalty and strive
                to enhance your customer experience at every step. Elevate your
                relationship with us – because you deserve more than just great
                products, you deserve exceptional benefits. Sign up now and let
                us show our appreciation for your continued support!"
              </p>
            </div>

          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row pb-4">
            <div className="col-lg-6 align-self-center order-lg-first mt-4">
              <img src="/assets/crmthree.jpg" style={{ width: "100%",borderRadius:'9px' }} />
            </div>
            <div className="col-lg-6 order-first align-self-center my-1">
              <h3 className="privacy_policy_heading_global_get">
                Analytics and Reporting:
              </h3>
              <p className="customer_web-para">
                Unlock insights with our CRM Analytics and Reporting tools!
                Elevate your business strategy by tracking customer
                interactions, analyzing data trends, and generating actionable
                reports. Stay ahead with real-time information, streamline
                decision-making, and enhance customer relationships
                effortlessly. Experience the power of intuitive analytics for
                effective CRM management on our user-friendly platform. Drive
                success, understand your customers, and make informed decisions
                with our cutting-edge analytics solutions."
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-12 my-1">
              <h3 className="privacy_policy_heading_global_get">CRM Software:</h3>
              <p className="customer_web-para">
                Unlock business growth with our intuitive CRM software.
                Streamline customer interactions, boost sales, and enhance
                customer satisfaction effortlessly. Our user-friendly platform
                empowers you to manage contacts, track leads, and nurture
                relationships seamlessly. Elevate your customer relationship
                management game with our cutting-edge solution, designed to
                optimize efficiency and drive success. Experience the power of
                personalized connections and watch your business thrive with our
                CRM software."
              </p>
              <h3 className="privacy_policy_heading_global_get">
                Consistent Branding:
              </h3>
              <p className="customer_web-para">
                Elevate your brand with seamless CRM solutions. Forge lasting
                connections with customers through our user-friendly platform.
                Streamline communication, boost efficiency, and leave a lasting
                impression. Experience consistent branding that speaks volumes.
                Revolutionize customer relationships effortlessly with our CRM
                expertise."
              </p>
            </div>
          </div>
        </div>
      </section>
   
      <Footer />
    </div>
  );
}
