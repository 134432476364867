import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";

const Navbar = () => {
  const state = useSelector((state) => state.handleCart);
  const [isServices, setisServices] = useState(false);
  const [isProduct, setisProduct] = useState(false);

  const [isColor, setIsColor] = useState("color_change_diactive");
  const changeNavBg = () => {
    //  window.scrollY >= 800 ? setNavBg(true) : setNavBg(false);
    window.scrollY < 40
      ? setIsColor("color_change_active")
      : setIsColor("color_change_diactive");
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
  });
  const location = useLocation();
  console.log("Nav", location.pathname == "/Education_Form");
  return (
    <div className="webNavbar">
      <nav
        // className={`navbar navbar-expand-lg  sticky-top nav_bars  ${isColor}`}
        className={`navbar navbar-expand-lg  sticky-top nav_bars  color_change_diactive`}
        style={{
          position: "fixed",
          width: "100%",
        }}
      >
        <div className="container px-0">
          <NavLink
            className="navbar-brand fw-bold fs-4 px-2 nav_logo_website"
            to="/"
          >
            <img
              src={
                location.pathname == "/Gaming"
                  ? "/assets/DVKLogo.png"
                  : "/assets/logo.png"
              }
              className="logo_virtualworld"
            />
          </NavLink>
          <button
            className="navbar-toggler mx-2"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul
              className="navbar-nav text-center"
              style={{ justifyContent: "space-between", width: "78%" }}
            >
              <li className="nav--item">
                <NavLink
                  className={`${
                    location.pathname == "/" ? "nav_bar" : "nav--link"
                  }`}
                  to="/"
                >
                  Home{" "}
                </NavLink>
              </li>
              <li className="nav--item">
                <button
                  className="nav--link"
                  onClick={() => setisServices(!isServices)}
                >
                  Services{" "}
                </button>
                {isServices && isServices ? (
                  <Modal
                    open={isServices}
                    onClose={() => setisServices(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <div class="subnav-content">
                      <h3 className="sub_nav_header">2K Services</h3>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          alignItems: "center",
                          justifyContent: "space-between",
                          Gap: "10px",
                          padding: "20px",
                        }}
                      >
                        <NavLink
                          to="/WebApplication"
                          className="web_application"
                          onClick={() => setisServices(false)}
                        >
                          <img
                            src="/assets/web-design.png"
                            style={{ width: "50px" }}
                          />
                          <p
                            style={{
                              fontfamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "26px",
                              display: "flex",
                              alignItems: "center",
                              margin: "0px",
                              color: "#000000",
                            }}
                          >
                            Web site & Web Application
                          </p>
                        </NavLink>

                        <NavLink
                          to="/MobileApps"
                          className="web_application"
                          onClick={() => setisServices(false)}
                        >
                          <img
                            src="/assets/app-development.png"
                            style={{ width: "50px" }}
                          />
                          <p
                            style={{
                              fontfamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "38px",
                              display: "flex",
                              alignItems: "center",
                              margin: "0px",
                              color: "#000000",
                            }}
                          >
                            Mobile Application
                          </p>
                        </NavLink>
                        <NavLink
                          to="/Logo"
                          className="web_application"
                          onClick={() => setisServices(false)}
                        >
                          <img
                            src={"/assets/graphic-designer.png"}
                            style={{ width: "40px", marginRight: "7px" }}
                          />
                          <p
                            style={{
                              fontfamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "38px",
                              display: "flex",
                              alignItems: "center",
                              margin: "0px",
                              color: "#000000",
                            }}
                          >
                            Logo Design
                          </p>
                        </NavLink>
                      </div>
                      <div
                        className="border_sub_nav"
                        style={{ padding: "20px" }}
                      >
                        <NavLink
                          to="/Contact"
                          className="web_application"
                          onClick={() => setisServices(false)}
                        >
                          <img
                            src="/assets/Nav/demo.png"
                            style={{ width: "50px" }}
                          />
                          <p
                            style={{
                              fontfamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "38px",
                              display: "flex",
                              alignItems: "center",
                              margin: "0px",
                              color: "#000000",
                            }}
                          >
                            Schedule a Demo
                          </p>
                        </NavLink>
                      </div>
                    </div>
                  </Modal>
                ) : (
                  <></>
                )}
              </li>
              <li className="nav--item">
                <button
                  className="nav--link"
                  onClick={() => setisProduct(!isProduct)}
                >
                  Product{" "}
                </button>
                <div className="web_sub_navbar">
                  {isProduct && isProduct ? (
                    <Modal
                      open={isProduct}
                      onClose={() => setisProduct(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <div className="web_sub_navbar">
                        <div className="subnav-content">
                          <h3 className="sub_nav_header">2K Product</h3>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                              Gap: "10px",
                              padding: "20px",
                            }}
                          >
                            <NavLink
                              to="/Billing"
                              className="web_application"
                              onClick={() => setisProduct(false)}
                            >
                              <img
                                src="/assets/Nav/bill.png"
                                style={{ width: "30px" }}
                              />
                              <p
                                style={{
                                  fontfamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  lineHeight: "38px",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px",
                                  color: "#000000",
                                }}
                              >
                                Billing
                              </p>
                            </NavLink>

                            <NavLink
                              to="/StockManagement"
                              className="web_application"
                              onClick={() => setisProduct(false)}
                            >
                              <img
                                src="/assets/Nav/inspection.png"
                                style={{ width: "40px", marginRight: "9px" }}
                              />
                              <p
                                style={{
                                  fontfamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  lineHeight: "38px",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px",
                                  color: "#000000",
                                }}
                              >
                                Stock Management
                              </p>
                            </NavLink>
                            <NavLink
                              to="/CRM"
                              className="web_application"
                              onClick={() => setisProduct(false)}
                            >
                              <img
                                src="/assets/Nav/crm.png"
                                style={{ width: "40px", marginRight: "7px" }}
                              />
                              <p
                                style={{
                                  fontfamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  lineHeight: "38px",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px",
                                  color: "#000000",
                                }}
                              >
                                CRM
                              </p>
                            </NavLink>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                              Gap: "10px",
                              padding: "20px",
                            }}
                          >
                            {/* <NavLink to="/Technology" className="web_application" onClick={()=>setisProduct(false)}>
                      <img
                        src="/assets/Nav/scholarship.png"
                        style={{ width: "40px",marginRight:'9px' }}
                      />
                      <p
                        style={{
                          fontfamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "38px",
                          display: "flex",
                          alignItems: "center",
                          margin: "0px",
                          color: "#000000",
                        }}
                      >
                    Technology
                      </p>
                    </NavLink> */}
                            <NavLink
                              to="/VisitorManagement"
                              className="web_application"
                              onClick={() => setisProduct(false)}
                            >
                              <img
                                src="/assets/Nav/team.png"
                                style={{ width: "40px" }}
                              />
                              <p
                                style={{
                                  fontfamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  lineHeight: "38px",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px",
                                  color: "#000000",
                                }}
                              >
                                Visitor Management
                              </p>
                            </NavLink>
                            <NavLink
                              to="/2K-Treat"
                              className="web_application_demo"
                              onClick={() => setisProduct(false)}
                            ></NavLink>
                          </div>
                          <div
                            className="border_sub_nav"
                            style={{ padding: "20px" }}
                          >
                            <NavLink
                              to="/Contact"
                              className="web_application"
                              onClick={() => setisProduct(false)}
                            >
                              <img
                                src="/assets/Nav/demo.png"
                                style={{ width: "50px" }}
                              />
                              <p
                                style={{
                                  fontfamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  lineHeight: "38px",
                                  display: "flex",
                                  alignItems: "center",
                                  margin: "0px",
                                  color: "#000000",
                                }}
                              >
                                Schedule a Demo
                              </p>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="phone_sub_navbar"></div>
              </li>
              <li className="nav--item">
                <NavLink
                  className={`${
                    location.pathname == "/Education" ||
                    location.pathname == "/Education_Form"
                      ? "nav_bar"
                      : "nav--link"
                  }`}
                  to="/Education"
                >
                  Education
                </NavLink>
              </li>
              <li className="nav--item">
                <NavLink
                  className={`${
                    location.pathname == "/Gaming" ? "nav_bar" : "nav--link"
                  }`}
                  to="/Gaming"
                >
                  Gaming
                </NavLink>
              </li>
              <li className="nav--item">
                <NavLink
                  className={`${
                    location.pathname == "/Aboutus" ? "nav_bar" : "nav--link"
                  }`}
                  to="/Aboutus"
                >
                  About Us
                </NavLink>
              </li>
              {/* <li className="nav--item">
              <NavLink className={`${location.pathname == '/Career'?'nav_bar':'nav--link'}`} to="/Career">
                Career
              </NavLink>
            </li> */}
              <li className="nav--item">
                <NavLink
                  className={`${
                    location.pathname == "/Contact" ? "nav_bar" : "nav--link"
                  }`}
                  to="/Contact"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
            {/* <div className="buttons text-center">
                       <NavLink to="/cart" className="btn btn-outline-dark m-2">Free Demo </NavLink>
                    </div> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
