import React, { useEffect, useState } from 'react'

export default function ScrollTop() {
  const [isVisible, setIsVisible] = useState(false);
const [iswhatsapp,setIsWhatsapp]=useState(false)
const [isMess,setIsMess]=useState('')
  // Show button when page is scrolled down 120px
  const toggleVisibility = () => {
    if (window.scrollY > 120) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };

  useEffect(() => {
    // Add event listener for scrolling
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      // Clean up the event listener
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);
  const sendMessage = () => {
    const phoneNumber = "8667079176"; // Recipient's WhatsApp number
    const message = `Hi, 2K Virtualworld ${isMess}`; // Your pre-filled message
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Navigate to WhatsApp link
    window.open(whatsappLink, "_blank");
  };
  
  return (

    <div style={{ display: "flex", flexDirection: "column", alignItems: "end",justifyContent:"end",gap:"1px" }}>
    {iswhatsapp?
  <div style={{position:"revert"}}>
  <img src='/assets/startIcon/whatsapp.png' style={{width:"100%",height:"100%"}}/>
  <input placeholder='text' style={{position:"absolute",left:0,bottom:isVisible ? `calc(100% - 75%)` : "17%",outline:"none",border:"none",height:'55px',width:"99%",borderRadius:"0px 0px 10px 10px",padding:"10px"}} onChange={(e)=>setIsMess(e.target.value)}/>
  <div style={{width:"30px",height:"30px",position:"absolute",right:10,bottom:isVisible ? `calc(100% - 73%)` :`calc(100% - 80%)`}} onClick={sendMessage}>
  <img src='/assets/startIcon/sendarrow.png' style={{width:"100%",height:"100%"}}/>
  </div>
  </div>

    
    :<>

    </>}
    <div>

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", background: "#fff", alignItems: "center", alignSelf: "center", padding: "10px 20px", borderRadius: "90px", cursor: "pointer", zIndex: 99, boxShadow: "0 1px 15px rgba(32, 33, 36, 0.28)", marginBottom: "9px",marginTop:"20px",gap:"5px" }}   onClick={()=>
          setIsWhatsapp(!iswhatsapp)}>
        <div
          style={{
            width: '30px',
            height: '30px',
          }}
          onClick={()=>
          setIsWhatsapp(!iswhatsapp)}
        >
          <img
            src="/assets/whatsapp.png"
            alt="whatsappIcon"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <p style={{ margin: "0px" }}>
          Chat With Us
        </p>
      </div>
      </div>
      <div onClick={scrollToTop} style={{
        display: isVisible ? "flex" : "none", background: '#4759aa',
        padding: '20px',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        justifyContent: 'center',
        alignItems: 'center', cursor: "pointer"
      }}>

        <i class="fa fa-arrow-up" aria-hidden="true" style={{ color: "#fff", fontWeight: 400, fontSize: 22 }}></i>
      </div>
    </div>

  )
}
