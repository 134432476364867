import React from "react";
import { Footer, Navbar } from "../../components";
import ScrollTop from "../../components/ScrollTop";

function Web_Application() {
  const lang = [
    { name: "React JS", bgImage: "assets/eduIcon/react_js.png" },
    { name: "HTML", bgImage: "assets/eduIcon/html.png" },
    { name: "React Native", bgImage: "assets/eduIcon/react-native-logo.png" },
    { name: "Java Script", bgImage: "assets/eduIcon/js.png" },
    { name: "Boot Strap", bgImage: "assets/eduIcon/bootstrap.png" },
    { name: "Angular JS", bgImage: "assets/eduIcon/angular.png" },
  ];
  return (
    <div style={{position:"relative"}}>
         <div style={{
        position: 'fixed',
        display: 'flex',
        bottom: '25px',
        right: '0px',
        zIndex: 99,
      }}>

        <ScrollTop />
      </div>
      <section className="">
        <div className="container mobileapplivation webApplivation mt-5 pt-5 pb-2">
          <div className="row">
            <div className="col-lg-12  align-self-center">
              <h3 className="hero_content--mobileApp ">
                Web Application Development
              </h3>
              {/* <p></p> */}
            </div>

            <div className="col-lg-12">
              <p className="dev_application_para--webApp text-center ">
                Boost your business with Radixweb’s high-performance, secure web
                apps built on modern frameworks and 23 years of expertise. Gain
                a competitive edge with innovative, future-proof web solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mb-5  pt-4">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="customer_web-header">
                Custom Web Application Development:{" "}
              </h4>
              <p className="customer_web-para">
                Enterprise web app development includes building dynamic ERP,
                CRM, project management systems, finance management solutions,
                collaboration tools, etc. Get our seasoned developers onboarded
                with high-end expertise in latest technologies and frameworks to
                develop sophisticated web apps that powers hundreds of
                workflows.
              </p>
            </div>
            <div className="col-lg-6">
              <h4 className="customer_web-header">Frontend Development:</h4>
              <p className="customer_web-para">
                Creation of the user interface (UI) and user experience (UX)
                components of web applications using HTML, CSS, and JavaScript
                frameworks like React.js, Angular, or Vue.js.
              </p>
              <h4 className="customer_web-header">Backend Development:</h4>
              <p className="customer_web-para">
                Building the server-side logic, database interactions, and
                application programming interfaces (APIs) using programming
                languages such as Python, Node.js, Ruby on Rails, or PHP, and
                frameworks like Django, Express.js, or Laravel.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src="/assets/front_end_back.png"
                style={{ width: "100%", borderRadius: "9px" }}
              />
            </div>
            <div className="col-lg-12 mt-4">
              <h4 className="customer_web-header">Full-Stack Development:</h4>
              <p className="customer_web-para">
                Comprehensive development of both frontend and backend
                components of web applications, covering the entire stack from
                database to user interface.
              </p>
            </div>
            <div className="col-lg-12">
              <h4 className="customer_web-header">
                Mobile Web App Development:{" "}
              </h4>
              <p className="customer_web-para">
                Designing and developing web applications optimized for mobile
                devices, ensuring responsive design and performance across
                different screen sizes and platforms.
              </p>
            </div>
            <div className="col-lg-12">
              <h4 className="customer_web-header">
                Progressive Web App (PWA) Development:
              </h4>
              <p className="customer_web-para">
                Creating web applications with progressive enhancement features,
                enabling offline access, push notifications, and app-like
                experiences on the web.
              </p>
            </div>
            {/*  */}
            <div className="col-lg-12 pt-3 pb-3 mb-3">
              <div style={{ display: "flex", flexWrap: "wrap",justifyContent:"center" }}>
                {lang.map((course) => {
                  return (
                    <>


                    {/* <div
                      // className="col-lg-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    > */}
                      {/* width:88 */}
                      {/* <div
                        style={{
                          //  width: '135px',
                          // height: '152px',
                          margin: "10px",
                          // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          // backgroundImage: `url(${})`,
                          // backgroundSize: "contain",
                          // backgroundPosition: "top",
                          // padding:"10px"
                        }}
                      > */}
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            // height: "217px",
                            width: "150px",
                          }}
                        >
                          <img
                            src={course.bgImage}
                            style={{
                              width: "65%",
                              height: "65%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      {/* </div> */}
                    {/* </div> */}
                    </>
                  );
                })}
              </div>
              {/* <img src="/assets/weblogo.png" style={{width:'100%'}}/> */}
            </div>
            {/*  */}
            <div className="col-lg-12">
              <h4 className="customer_web-header">E-commerce Development:</h4>
              <p className="customer_web-para">
                Building online stores and e-commerce platforms with features
                like product catalog, shopping cart, payment gateway
                integration, and order management.
              </p>
            </div>
            <div className="col-lg-12">
              <h4 className="customer_web-header">
                Content Management System (CMS) Development:
              </h4>
              <p className="customer_web-para">
                Developing custom CMS solutions or customizing existing CMS
                platforms like WordPress, Joomla, or Drupal to manage website
                content efficiently.
              </p>
            </div>
            <div className="col-lg-12">
              <h4 className="customer_web-header">
                API Development and Integration:
              </h4>
              <p className="customer_web-para">
                Designing and implementing RESTful APIs or GraphQL APIs for
                integrating web applications with third-party services,
                databases, or other systems.
              </p>
            </div>
            <div className="col-lg-6">
              <h4 className="customer_web-header">
                Quality Assurance and Testing:
              </h4>
              <p className="customer_web-para">
                Conducting thorough testing of web applications to ensure
                functionality, performance, security, and compatibility across
                different browsers and devices.
              </p>
              <h4 className="customer_web-header">Maintenance and Support:</h4>
              <p className="customer_web-para">
                Providing ongoing maintenance, updates, and technical support
                services to keep web applications secure, up-to-date, and
                optimized for performance.
              </p>
            </div>
            <div className="col-lg-6">
              <img src="/assets/web_testing.png" />
            </div>
            <div className="col-lg-12 mt-3">
              <h4 className="customer_web-header">
                When choosing a web development service provider, it's essential
                to consider their expertise, portfolio, technology stack,
                project management approach, and client reviews to ensure they
                can deliver the desired results effectively and efficiently.
              </h4>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="web_process">
                Process We Follow for Our Web App Development Services
              </h4>
              <img src="" />
              <div style={{ textAlign: "center" }}>
                <a href="/Contact" className="discuss_idea">
                  Let’s Discuss your idea
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <Footer />
    </div>
  );
}

export default Web_Application;
