import React from "react";
import Marquee from "react-fast-marquee";

export default function Techlogo() {
  return (
    <>
      <Marquee speed={30} >
        <div  className='technology_ping' style={{ width: "150px",  margin: "0px 30px",objectFit:"contain" }}>
          <img src="/assets/techlogo/javalogo.png" style={{ width: "100%",objectFit:"contain" }} />
        </div>
        <div  className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img
            src="/assets/techlogo/SpringBootlogo.png"
            style={{ width: "100%",objectFit:"contain" }}
          />
        </div>
        <div  className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img
            src="/assets/techlogo/seleniumlogo.png"
            style={{ width: "100%",objectFit:"contain" }}
          />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img
            src="/assets/techlogo/SpringBootlogo.png"
            style={{ width: "100%" ,objectFit:"contain"}}
          />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img
            src="/assets/techlogo/testinglogo.png"
            style={{ width: "100%",objectFit:"contain" }}
          />
        </div>
      </Marquee>
      <Marquee direction="right" speed={30}>
        <div className='technology_ping' style={{ width: "150px",  margin: "0px 30px",objectFit:"contain" }}>
          <img src="/assets/techlogo/css_logo.png" style={{ width: "50%",objectFit:"contain" }} />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img src="/assets/techlogo/html_logo.png" style={{ width: "100%",objectFit:"contain" }} />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img src="/assets/techlogo/jslogo.png" style={{ width: "50%",objectFit:"contain" }} />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img
            src="/assets/techlogo/bootstraplogo.png"
            style={{ width: "100%",objectFit:"contain" }}
          />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img
            src="/assets/techlogo/angularlogo.png"
            style={{ width: "100%",objectFit:"contain" }}
          />
        </div>
      </Marquee>
      <Marquee direction="left" speed={30}>
        <div  className='technology_ping' style={{ width: "150px",  margin: "0px 30px" ,objectFit:"contain"}}>
          <img
            src="/assets/techlogo/reactjslogo.png"
            style={{ width: "100%",objectFit:"contain" }}
          />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img
            src="/assets/techlogo/oraclelogo.png"
            style={{ width: "100%",objectFit:"contain" }}
          />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px",objectFit:"contain" }}>
          <img src="/assets/techlogo/mysqllogo.png" style={{ width: "50%",objectFit:"contain" }} />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px" ,objectFit:"contain"}}>
          <img src="/assets/techlogo/tallylogo.png" style={{ width: "100%",objectFit:"contain" }} />
        </div>
        <div className='technology_ping' style={{ width: "190px",  margin: "0px 30px" ,objectFit:"contain"}}>
          <img src="/assets/techlogo/mslogo.png" style={{ width: "100%",objectFit:"contain" }} />
        </div>
      </Marquee>
    </>
  );
}
