
import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from "react-router-dom";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeNavbar = () => setIsOpen(false);
  const navRef = useRef();  // Create a ref for the nav element

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  const location = useLocation();
  return (
    <div className='mobile_navbar'>

    <nav className=" --changedNav" ref={navRef}>
    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%',background:'#fff'}}>
    <NavLink className="navbar-brand fw-bold fs-4 px-2 nav_logo_website" to="/" onClick={closeNavbar}>
          <img src={location.pathname=="/Gaming"?"/assets/DVKLogo.png":"/assets/logo.png"}
         className="logo_virtualworld" />
        </NavLink>
      <div className="menu-toggle" onClick={toggleDropdown} style={{    textAlign: 'end',paddingRight:'10px'}}>   
      {
        !isOpen?
      <img src='/assets/menu.png' style={{width:'10%'}}/>
:
      <img src='/assets/close.png' style={{width:'10%'}}/>
      } 
      </div>
    </div>
      {isOpen && (
        <ul className="nav-menu">
          <li><NavLink to="/" onClick={closeNavbar}>Home</NavLink></li>
          <li>
            <div onClick={(e) => e.preventDefault()}>Services</div>
            <ul className="dropdown">
              <li><NavLink to="/WebApplication" onClick={closeNavbar}> Web site & Web Application</NavLink></li>
              <li><NavLink to="/MobileApps" onClick={closeNavbar}>MobileApps</NavLink></li>
              <li><NavLink to="/Logo" onClick={closeNavbar}>Logo</NavLink></li>
            </ul>
          </li>
          <li>
            <div onClick={(e) => e.preventDefault()}> Product{" "}</div>
            <ul className="dropdown">
              <li><NavLink to="/Billing" onClick={closeNavbar}>Billing</NavLink></li>
              <li><NavLink to="/StockManagement" onClick={closeNavbar}>StockManagement</NavLink></li>
              <li><NavLink to="/CRM" onClick={closeNavbar}>CRM</NavLink></li>
              {/* <li><NavLink to="/Technology" onClick={closeNavbar}>Technology</NavLink></li> */}
              <li><NavLink to="/VisitorManagement" onClick={closeNavbar}>VisitorManagement</NavLink></li>
            </ul>
          </li>
          <li><NavLink to="/Education" onClick={closeNavbar}>Education</NavLink></li>
          <li><NavLink to="/Gaming" onClick={closeNavbar}>Gaming</NavLink></li>
          <li><NavLink to="/Aboutus" onClick={closeNavbar}>About</NavLink></li>
          <li><NavLink to="/Contact" onClick={closeNavbar}>Contact</NavLink></li>
        </ul>
      )}
    </nav>
    </div>
  );
};

export default Navbar;
