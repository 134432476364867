import { Navbar, Main, Product, Footer } from "../components";
import Mobilenav from "../components/Mobilenav";
import Education from "./Education";

function Home() {
  return (
    <>
      <Main />
    
      <Footer />
    </>
  )
}

export default Home