import React, { useEffect, useState } from "react";
import Product from "./Products";
import CustomerLogo from "./CustomerLogo";
import { Link } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import ScrollTop from "./ScrollTop";

const Home = () => {
 
  return (
    <div style={{ position: "relative" }}>
         <div style={{
        position: 'fixed',
        display: 'flex',
        bottom: '25px',
        right: '0px',
        zIndex: 99,
      }}>

        <ScrollTop />
      </div>
      <section>
        <div className="container header_section">
          <div className="row ">
            <div className="col-lg-12 hero_section_row mt-4" >
              <h3 className="hero_content pt-5">2K Virtual World</h3>
              <h4 className="hero_text ">Global View of Your Business</h4>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-12 ">
              <p className="pt-2 services_header " style={{}}>Our Mission</p>
            </div>

          </div>
          <div className="row pt-3 pb-3">
            <div className="col-lg-6 align-self-center">
              <h5 className="service_sub_heading">Website Development</h5>
              <p className="services_para mt-3 mb-2">
                In today&apos;s digital landscape, websites play a pivotal role
                in defining the online identity of your business, regardless of
                its scale or industry.
              </p>
              <p className="services_para mt-3 mb-0">
                At 2K Virtual world, we don&apos;t just offer web development and
                web Application services; we&apos;re your dedicated technology
                partner, driven by a singular vision to unleash digital
                potential through scalable, cutting-edge, secure, and
                unparalleled web development solutions.
              </p>
            </div>
            <div className="col-lg-6 align-self-center mt-4">
              <img src="/assets/startIcon/web.png" alt="" style={{ width: '100%', borderRadius: '9px' }} />
            </div>
          </div>

          <div className="row depenseed pb-3 mb-1">
            <div className="col-lg-6 align-self-center order-lg-first mt-4">
              <div style={{ width: '100%' }}>

                <img src="/assets/mobile-app.png" style={{ width: '100%', borderRadius: '9px', height: "100%" }} />
              </div>
            </div>
            <div className="col-lg-6 order-first align-self-center mt-4">
              <h5 className="service_sub_heading">Mobile App Development</h5>
              <p className="services_para mt-3 mb-2">
                We deliver revolutionary mobile application experiences that inspire
                user engagement & conversion.
              </p>
              <p className="services_para mt-3 mb-2">
                Get in touch to achieve app-driven business success
              </p>
              <div className="d-flex flex-row align-items-center">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  Cost-effective & Quality solution
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-2 mb-2">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  2x Faster & agile development
                </p>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  Flexible engagement options
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-2 mb-2">
                <div className="dot_service"></div>&nbsp;
                <p className="services_para pt-0 mb-0">
                  Make app experience more futuristic
                </p>
              </div>
            </div>
          </div>

          <div className="row  depenseed ">
            <div className="col-lg-6 align-self-center">
              <h5 className="service_sub_heading">Logo Design</h5>
              <p className="services_para mt-3 mb-2">
                Custom logo design made for your business
              </p>
              <p className="services_para mt-3 mb-2">
                Grow your business with a professional, custom logo designed by
                our creative experts. Because good design makes great business.
              </p>
            </div>
            <div className="col-lg-6 align-self-center mt-3 ">
              <img src="/assets/logodesign.jpg" style={{ width: '100%', height: "100%", objectFit: "contain", borderRadius: "9px" }} />
            </div>
          </div>

        </div>
      </section>
      <section>
        <div className="container mt-1 mb-2 depenseed">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="services_header">Our Product</h3>
            </div>
          </div>
          <div className="row">
            <Product />
          </div>

        </div>
      </section>

      <section className="getstarted_section">
        <div className="container">
          <div className="row pt-3  pb-5 ">
            <div className="col-lg-6 align-self-center order-lg-first">
              <img src="/assets/demo.png" style={{ width: "100%" }} />
            </div>
            <div className="col-lg-6 align-self-center order-first mb-4">
              <h5 className="service_sub_heading">Get Started For Free!</h5>
              <p className="services_para mt-4 mb-3">
                Analyzing and reporting of web data for purposes of
                understanding and optimizing web usage.
              </p>
              <NavLink to="/Contact" className="schedule_demo">SCHEDULE A CALL</NavLink>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row mt-5 ">
            <div className="col-lg-6 align-self-center">
              <h5 className="service_sub_heading">We are 2K Virtual World</h5>
              <p className="services_para mt-3 mb-4">
                2KVirtualWorld is a vibrant company in Chennai, India, known for
                its young and energetic team dedicated to solving business
                problems ethically. Our goal is to make a lasting impact by
                transforming people and organizations, with a focus on
                collaborative partnerships with our customers.
              </p>
              <p className="explore_more mb-2">
                Explore more about &nbsp;
                <span className="linner_gradent">2K Virtual World</span>
              </p>
              <div className="explore_nav">
                <div>
                  <div className="explore_sections_Navigartion">

                    <div className="explore_icon">
                      <img src="/assets/about_icon.png" />
                    </div>
                    <a href="/Aboutus" className="url">
                      About 🡥

                    </a>
                  </div>
                  <div className="explore_sections_Navigartion">
                    <div className="explore_icon">
                      <img src="/assets/policies_icon.png" />
                    </div>
                    <a href="/PrivacyPolicy" className="url">
                      Policy
                      🡥
                    </a>


                  </div>
                  <div className="explore_sections_Navigartion">
                    <div className="explore_icon">
                      <img src="/assets/education_icon.png" style={{ width: "30px" }} />
                    </div>
                    <a href="/Education" className="url">
                      Education 🡥
                    </a>
                  </div>

                </div>
                <div>
                  <div className="explore_sections_Navigartion">
                    <div className="explore_icon">
                      <img src="/assets/contact.png" />
                    </div>
                    <a href="/Contact" className="url">
                      Contact 🡥

                    </a>
                  </div>
                  <div className="explore_sections_Navigartion">
                    <div className="explore_icon">
                      <img src="/assets/mobile_app.png" />
                    </div>
                    <a href="/MobileApps" className="url">
                      Mobile App
                      🡥
                    </a>


                  </div>
                  <div className="explore_sections_Navigartion">
                    <div className="explore_icon">
                      <img src="/assets/crm_icon.png" style={{ width: "30px" }} />
                    </div>
                    <a href="/CRM" className="url">
                      CRM 🡥

                    </a>
                  </div>

                </div>

              </div>
            </div>
            <div className="col-lg-6 align-self-center mt-4">
              <img src="/assets/teamwork.jpg" style={{ width: "100%", objectFit: 'contain', borderRadius: '9px' }} />
            </div>
          </div>
        </div>
      </section>
      <section className="client_logo_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-2 mb-0 pt-3 pb-0">
              <h2 className="client_logo_title">Client Logos</h2>
              <CustomerLogo />
            </div>
          </div>
        </div>
      </section>
     



    </div>
  );
};
export default Home;
const styles = {
  scrollToTop: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    fontSize: '30px',
    cursor: 'pointer',
    zIndex: '1000',
    color: '#000',
    display: 'block',
  },
};
