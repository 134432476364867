import React from "react";
import { Footer, Navbar } from "../../components";
import ScrollTop from "../../components/ScrollTop";
export default function MobilApps() {
  return (
    <div style={{position:"relative"}}>
         <div style={{
        position: 'fixed',
        display: 'flex',
        bottom: '25px',
        right: '0px',
        zIndex: 99,
      }}>

        <ScrollTop />
      </div>
      <section>
        <div className="container mobileapplivation--iop">
          <div className="row mobile_header hero_section_row_mobile  px-0">
            <div className="col-sm-6 col-lg-6 text-center d-flex flex-column align-items-center px-0 ">
              <h3 className="hero_content--mobileApp">
                Mobile Application Development
              </h3>
              <p className="dev_application_para--mobileApp  text-center">
                We help global brands design and build superior digital
                products, enabling seamless user experiences across all modern
                platforms and devices
              </p>
              {/* <p></p> */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-3 mb-2">
          <div className="row">
            <div className="col-lg-12 mt-2">
              <h4 className="customer_web-header ">
                Custom Mobile App Development:
              </h4>
              <p className="customer_web-para">
                Tailored development of mobile applications to meet specific
                business needs and objectives, leveraging native or
                cross-platform development frameworks.
              </p>
            </div>

            <div className="col-lg-6 align-self-center my-2">
              <h4 className="customer_web-header">iOS App Development:</h4>
              <p className="customer_web-para">
                Designing and building mobile applications for Apple devices
                such as iPhones and iPads using Swift or Objective-C programming
                languages and Apple's development tools (Xcode).
              </p>
              <h4 className="customer_web-header">Android App Development:</h4>
              <p className="customer_web-para">
                Creating mobile apps for Android devices using Java or Kotlin
                programming languages and Google's Android SDK, Android Studio,
                or other development platforms.
              </p>
            </div>

            <div className="col-lg-6 my-1">
              <img src="/assets/android.png" style={{ width: "100%" }} />
            </div>

            <div className="col-lg-12 my-1">
              <h4 className="customer_web-header mt-5">
                Cross-Platform App Development:
              </h4>
              <p className="customer_web-para ">
                Developing mobile apps that can run on multiple platforms (iOS,
                Android, and sometimes others like Windows) using frameworks
                like React Native, Flutter, Xamarin, or Ionic.
              </p>
            </div>

            <div className="col-lg-12 my-1">
              <h4 className="customer_web-header">Hybrid App Development:</h4>
              <p className="customer_web-para">
                Hybrid app development refers to the process of creating mobile
                applications that can run on multiple platforms (such as iOS,
                Android, and sometimes even web browsers) using a single
                codebase.
              </p>
            </div>

            <div className="col-lg-12 my-1">
              <h4 className="customer_web-header">UI/UX Design:</h4>
              <p className="customer_web-para">
                Designing intuitive and visually appealing user interfaces (UI)
                and optimizing user experience (UX) to enhance engagement and
                usability across different mobile devices and screen sizes.
              </p>
            </div>

            {/*  */}
            {/* <div className="col-lg-12 pt-3 pb-3 mb-3">
              <img
                src="/assets/mobileapp_strip.png"
                style={{ width: "100%" }}
              />
            </div> */}
            {/*  */}
            <div className="col-lg-12 my-1">
              <h4 className="customer_web-header">Backend Development:</h4>
              <p className="customer_web-para">
                Setting up server-side infrastructure, databases, and APIs to
                support mobile app functionality, including user authentication,
                data storage, and integration with external services
              </p>
            </div>

            <div className="col-lg-12 my-1">
              <h4 className="customer_web-header">
                CAPI Development and Integration:
              </h4>
              <p className="customer_web-para">
                Designing and implementing RESTful APIs or GraphQL APIs to
                facilitate communication between mobile apps and backend
                systems, third-party services, or cloud platforms.
              </p>
            </div>

            <div className="col-lg-6 align-self-center my-1">
              <h4 className="customer_web-header">
                Quality Assurance and Testing:
              </h4>
              <p className="customer_web-para">
                Conducting comprehensive testing of mobile apps to ensure
                functionality, performance, security, and compatibility with
                various devices, operating systems, and network conditions.
              </p>
              <h4 className="customer_web-header">
                App Store Submission and Deployment:
              </h4>
              <p className="customer_web-para">
                Assisting with the submission process to app stores (Apple App
                Store, Google Play Store) and ensuring compliance with
                platform-specific guidelines and requirements.
              </p>
              <h4 className="customer_web-header">Maintenance and Support:</h4>
              <p className="customer_web-para">
                Providing ongoing maintenance, updates, and technical support
                services to address bugs, performance issues, security
                vulnerabilities, and compatibility issues as mobile platforms
                evolve.
              </p>
            </div>

            <div className="col-lg-6">
              <img src="/assets/mobile_lap.png" style={{ width: "100%" }} />
            </div>

            <div className="col-lg-12 my-1">
              <h4 className="customer_web-header mt-4">
                App Analytics and Performance Monitoring:
              </h4>
              <p className="customer_web-para">
                Implementing analytics tools and monitoring solutions to track
                user engagement, app performance metrics, crash reports, and
                other key performance indicators (KPIs).
              </p>
            </div>

            <div className="col-lg-12 mb-1">
              <h4 className="customer_web-para">
                When selecting a mobile app development service provider, it's
                essential to consider their expertise, portfolio, development
                approach, project management methodology, and client
                testimonials to ensure they can deliver high-quality, scalable,
                and user-friendly mobile applications that align with your
                business goals
              </h4>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container  mb-5">
          <div className="row" >
            <div className="col-lg- mt-3">
              <h4 className="web_process">
                Process We Follow for Our Web App Development Services
              </h4>
              <img src="" />
              <div style={{ textAlign: "center" }}>
                <a href="/Contact" className="discuss_idea">
                  Let’s Discuss your idea
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <Footer />
    </div>
  );
}
