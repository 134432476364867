import React, { useState } from "react";
import { Footer, Navbar } from "../components";
import { NavLink } from "react-router-dom";
import Techlogo from "../components/Techlogo";
import ScrollTop from "../components/ScrollTop";
export default function Education() {
  const lang = [
    { name: "React JS", bgImage: "assets/eduIcon/react_js.png" },
    { name: "Core Java", bgImage: "assets/eduIcon/java.png" },
    { name: "Spring Boot", bgImage: "assets/eduIcon/Spring_Logo.png" },
    { name: "Selenium", bgImage: "assets/eduIcon/se.png" },
    { name: "Oracle", bgImage: "assets/eduIcon/oracle.png" },
    { name: "MySql", bgImage: "assets/eduIcon/mysqlicon.png" },
    { name: "HTML", bgImage: "assets/eduIcon/html.png" },
    { name: "React Native", bgImage: "assets/eduIcon/react-native-logo.png" },
    { name: "CSS", bgImage: "assets/eduIcon/css.png" },
    { name: "Java Script", bgImage: "assets/eduIcon/js.png" },
    { name: "Boot Strap", bgImage: "assets/eduIcon/bootstrap.png" },
    { name: "Angular JS", bgImage: "assets/eduIcon/angular.png" },
    { name: "MS Office", bgImage: "assets/eduIcon/msoffice.png" },
    { name: "Testing", bgImage: "assets/eduIcon/testing.png" },
    { name: "SAP", bgImage: "assets/eduIcon/sap.png" },
    { name: "Tally", bgImage: "assets/eduIcon/tally.png" },
    
  ];
  let Icon = [];
  const [isCourse, setIsCourse] = useState("");
  return (
    <div style={{position:'relative'}}>
          <div style={{
        position: 'fixed',
        display: 'flex',
        bottom: '25px',
        right: '0px',
        zIndex: 99,
      }}>

        <ScrollTop />
      </div>
        {/* <div className="container   education_top_section">
          <div className="row  align-items-center pt-5 pb-5 vh-100 mt-5">
            <div className="col-lg-6 px-5">
              <h3 className="education_title_heading">
             2K Technologies
              </h3>
              <p style={{fontSize:"20px",fontWeight:'600',color:"#fff"}}>Invest Perseverance to Transform your Career</p>
            </div>
            <div className="col-sm-6">
              <img
                src="/assets/education.png"
                style={{ width: "100%", padding: "50px" }}
              />
            </div>
            <div className="col-lg-12">
            </div>
            <div style={{ padding: "15px 0px" }}>
             
                <Techlogo />
              </div>
          </div>
        </div> */}
        <section >
          <div className="container mt-5 pt-5 mb-5 education_top_section ">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                {/* <h4 className="course_careers">
                2K Tech <br />
                Have Successfully Achieved Their Desired Careers In
              </h4> */}
              </div>
              <div class="col-lg-12 mb-5 pb-5 integrate_over_tools ">
                <div class="integrate_tools">
                  <div class="integrate_section">
                    <h2 class="integrate">
                      2K Technologies
                      <br />
                      Modern Work-Experience Based Learning Approach
                    </h2>
                    <p class="integrate_para">
                      Engage in real-world full-stack and backend web
                      development projects while receiving mentorship and
                      guidance to progress and excel in your career.
                    </p>
                    <a
                      href="/Education_Form"
                      class="schedule_demo_btn_integrate"
                      style={{ marginTop: "15px", zIndex: 10 }}
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
                <div style={{ padding: "15px 0px" }}>
                  {/* <Marquee /> */}
                  <Techlogo />
                </div>
              </div>
            </div>
          </div>
        </section>
  
      <section>
        <div className="container">
          <div className="row mt-5 pt-4  ">
            <div className="col-lg-12 align-self-center">
              <h3 className="best_selling">Best Learning Courses in India</h3>
              <h2 className="popular_career">
                Our Popular Career Advancement Training Courses
              </h2>
            </div>
          </div>
          <div className="row  mb-5 pb-4 ">

            {lang.map((course) => {
              return (
                <div className="col-lg-3" style={{    display: 'flex',
    justifyContent: 'center'}}>
                  {/* width:88 */}
                  <div
                    style={{
                      width: "230px",
                      margin: "10px",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      // backgroundImage: `url(${})`,
                      // backgroundSize: "contain",
                      // backgroundPosition: "top",
                      // padding:"10px"
                    }}
                    // style={{ width: "230px", margin :"10px",boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px` }}
                    className="card_main"
                    onClick={() => setIsCourse(course)}
                  >
                    <NavLink to="/Education_Form">
                      <div className="card_header">
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "217px",
                          }}
                        >
                          {/* <h3 className="label_heading">{course?.name}</h3>
                          <div>
                          </div> */}
                            <img src={course.bgImage}  style={{width:"100%",height:"100%",objectFit:"contain"}}/>
                        </div>
                        {/* <div>
                          <p className="edu_duration">Duration: 30 Days</p>
                          <p className="edu_duration">
                            All Delivery Methods Available: Yes
                          </p>
                        </div> */}
                      </div>
                      <a className="enquire-btn-head" href="./Education_Form">
                        <p className="enquire-btn">{course?.name} </p>
                        <img src="/assets/Chevron_Right.png" />
                      </a>
                    </NavLink>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-12 ">
              <h3 className="tech_dev_expertise">
                Experiential Learning Approach for Acquiring Highly Sought-After
                <br />
                Developer Expertise
              </h3>
              <div className="d-flex justify-content-center">
                <a href="/Education_Form" className="btn_apply">
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="tech_card">
                <img
                  src="/assets/Icon/professionals.png"
                  style={{ width: "40px" }}
                />
                <h4 className="tech_card_title">
                  Learn by Working like Tech Professionals
                </h4>
                <p className="tech_card_para">
                  Learn Full Stack Development, Backend Development, QA
                  Automation through an immersive project-based curriculum
                  focused on practical developer skills and real-world
                  scenarios.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img
                  src="/assets/Icon/companies.png"
                  style={{ width: "40px" }}
                />
                <h4 className="tech_card_title">
                  Gain Work-ex on Problems Given by Real Tech Companies
                </h4>
                <p className="tech_card_para">
                  Gain the work experience of building professional software
                  products for India’s top tech companies like CRED, Playment,
                  Jumbotail, Vicara, and others.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img
                  src="/assets/Icon/industry.png"
                  style={{ width: "40px" }}
                />
                <h4 className="tech_card_title">
                  Learn From Seasoned Industry Professionals in Real Time
                </h4>
                <p className="tech_card_para">
                  Participate in activity-based live working sessions
                  facilitated by industry mentors from companies like Microsoft,
                  Flipkart, Nilenso.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img src="/assets/Icon/roles.png" style={{ width: "40px" }} />
                <h4 className="tech_card_title">
                  Get career support to break through into ambitious roles
                </h4>
                <p className="tech_card_para">
                  Be prepared for every interview and land exciting development
                  jobs with structured planning and personalised guidance &
                  support from Crio career coaches.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img
                  src="/assets/Icon/recruiters.png"
                  style={{ width: "40px" }}
                />
                <h4 className="tech_card_title">
                  Build a GitHub portfolio that impresses recruiters
                </h4>
                <p className="tech_card_para">
                  Go beyond just certificates with a Crio verified project
                  portfolio on GitHub and impress any recruiter at a product
                  based company with your skills and experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="tech_card">
                <img src="/assets/Icon/work.png" style={{ width: "40px" }} />
                <h4 className="tech_card_title">
                  Stand out at interviews with real proof of work
                </h4>
                <p className="tech_card_para">
                  Validate your learnings and let your skills shine with a work
                  experience certificate that makes you stand out at interviews.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <Footer />
    </div>
  );
}
