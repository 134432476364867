import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./globals.css";
import "bootstrap/dist/css/bootstrap.css";
// import ScrollToTop from 'react-router-scroll-top';
// import ScrollToTop from 'react-router-scroll-top'
import {
  Home,
  Gameing,
  Contact,
  Freedemo,
  Web_Application,
  MobilApps,
  Logo,
  Billing,
  StockManagement,
  CRM,
  Ticketing,
  VisitorManagement,
  Terms,
  Treat,
  EduFrom,
  Education,
  PrivacyPolicy,
  ProfilePage,
  About,
  ScrollTop
} from "./pages";
import { Navbar } from "./components";
import Mobilenav from "./components/Mobilenav";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
    <ScrollTop />
    <Navbar/>
     <Mobilenav/>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/WebApplication" element={<Web_Application />} />
        <Route path="/MobileApps" element={<MobilApps />} />
        <Route path="/Logo" element={<Logo />} />
        <Route path="/Billing" element={<Billing />} />
        <Route path="/StockManagement" element={<StockManagement />} />
        <Route path="/CRM" element={<CRM />} />
        <Route path="/Technology" element={<Ticketing />} />
        <Route
          path="/VisitorManagement"
          element={<VisitorManagement />}
        />
        <Route path="/2K-Treat" element={<Treat />} />

        <Route path="/Education" element={<Education />} />
        <Route path="/Career" element={<Education />} />
        <Route path="/Gaming" element={<Gameing />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Aboutus" element={<About />} />
        <Route path="/freedemo" element={<Freedemo />} />
        <Route path="/Education_Form" element={<EduFrom />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/profilepage" element={<ProfilePage />} />
      </Routes>
    </Provider>
  </BrowserRouter>
);
