import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import ScrollTop from "../components/ScrollTop";
import { v4 as uuidv4 } from "uuid";
import Carousel from 'react-spring-3d-carousel';
import { config } from "react-spring";
export default function About() {
  const slides = [
    {
      key: uuidv4(),
      content: <img src="/assets/Nav/dvk.png" alt="1" />
    },
    {
      key: uuidv4(),
      content: <img src="/assets/Nav/tech.png" alt="2" />
    },
    {
      key: uuidv4(),
      content: <img src="/assets/Nav/virtual.png" alt="3" />
    },

  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [slides.length]);
  return (
    <div style={{ position: "relative" }}>
      <div style={{
        position: 'fixed',
        display: 'flex',
        bottom: '25px',
        right: '10px',
        zIndex: 99,
      }}>

        <ScrollTop />
      </div>
      {/*
      <section className="about_header_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="about_section_header">About 2K Virtual World</h3>
            </div>
          </div>
        </div>
      </section>
      <Footer /> */}
      <section>
        <div className="container about_header_section">
          <div className="row mt-5" style={{ width: "100%" }}>
            <div
              className="col-lg-6 offset-lg-6 pt-4 col-md-12 align-self-center"
              style={{ justifyContent: "end", display: "flex", flexDirection: "column" }}
            >
              <h3 className="about_section_header">About 2K Virtual World</h3>
              <div>
                {/* <marquee width="50%" height="25%">
              <div style={{display:"flex"}}>
              <div style={{width:"25%",height:"25%"}}>
                <img src='/assets/DVKLogo.png' width="100%" height="100%"/>
              </div>
              <div style={{width:"25%",height:"25%"}}>
                <img src='/assets/DVKLogo.png' width="100%" height="100%"/>
              </div>
              <div style={{width:"25%",height:"25%"}}>
                <img src='/assets/DVKLogo.png' width="100%" height="100%"/>
              </div>
              </div>
            
              </marquee> */}
                <div className="carsole_about">

                  <Carousel
                    slides={slides} goToSlide={currentIndex}
                    offsetRadius={2}
                    showNavigation={false}
                    animationConfig={config.gentle} />
                </div>
              </div>
              {/* <h3 className="about_section_header">About 2K Virtual World</h3> */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-3 mb-3">
          <div className="row pt-5 pb-2 mt-5">
            <div className="col-lg-6">
              <h3 className="about_design">
                We Create, Design and Make it Real
              </h3>
              <p className="about_design_para">
                The disposition of the company lies in providing the clients
                with precise and innovative software solutions not only to help
                them grow but also making them a conspicuous name in their
                field. The company’s objective is to excel and be a leader in
                its field by paving its growth foundation with client
                satisfaction and keeping pace with changing market needs and
                trends.
              </p>
              <p className="about_text">If one can do, we too can do!</p>
              <br />
              <p className="about_text">If no one can do, we must do!</p>
            </div>
            <div className="col-lg-6">
              <h3 className="about_design">What does 2K Virtual World do?</h3>
              <p className="about_design_para">
                In today's digital landscape, websites play a pivotal role in
                defining the online identity of your business, regardless of its
                scale or industry. At 2K Virtualworld, we don't just offer web
                development and web Application services; we're your dedicated
                technology partner, driven by a singular vision to unleash
                digital potential through scalable, cutting-edge, secure, and
                unparalleled web development solutions.
              </p>
            </div>
            {/* <div className="col-lg-6">
              <img src="" />
            </div> */}
          </div>
        </div>
      </section>
      {/* <section>
        <div className="container mt-0 mb-5">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="about_design">What does 2K Virtual World do?</h3>
              <p className="about_design_para">
                In today's digital landscape, websites play a pivotal role in
                defining the online identity of your business, regardless of its
                scale or industry. At 2K Virtualworld, we don't just offer web
                development and web Application services; we're your dedicated
                technology partner, driven by a singular vision to unleash
                digital potential through scalable, cutting-edge, secure, and
                unparalleled web development solutions.
              </p>
            </div>
            <div className="col-lg-6">
              <img src="" />
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
}
