import React from "react";
import { Footer, Navbar } from "../components";

export default function Gameing() {
  return (
    <>
      <section className="">
        <div className="container gameing_header_section">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="gameing_title_main">2K VIRTUAL REALITY GAMING</h2>
          
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row mt-3 depenseed pb-2  mb-0">
            <div className="col-lg-6 align-self-center">
              <h3 className="gameing_title_virtual">
                What is the concept of virtual reality?{" "}
              </h3>
              <p className="gameing_para_virtual">
                &#x2022; Virtual Reality (VR) is a computer-generated
                environment with scenes and objects that appear to be real,
                making the user feel they are immersed in their surroundings.
                This environment is perceived through a device known as a
                Virtual Reality headset.
              </p>
              <p className="gameing_para_virtual">
                &#x2022;Virtual reality allows users to create simulated,
                interactive, and specifically designed environments for specific
                use. It is designed for human interaction and a specific reason
                to create experiences.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src="./assets/gameing/gameingfirst.png"
                style={{ width: "100%", height: "100%",borderRadius:'9px' }}
              />
            </div>
          </div>
          <div className="row pt-3 pb-3 mb-1">
            <div className="col-lg-6 align-self-center order-lg-first mt-2">
              <img
                src="./assets/gameing/gaming.png"
                style={{ width: "100%", height: "100%",borderRadius:'9px' }}
              />
            </div>
            <div className="col-lg-6 align-self-center order-first">
              <h3 className="gameing_title_virtual">
                What does virtual reality gaming do?
              </h3>
              <p className="gameing_para_virtual">
                &#x2022; VR gaming systems generate realistic sensations that
                simulate users' physical presence in a computer-generated
                environment. The goal of VR gaming systems is to let users
                believe they inhabit a virtual world. People using VR gaming
                systems move around the virtual world and interact with virtual
                features and items.
              </p>
              <h3 className="gameing_title_virtual">
                What are the advantages of VR gaming?
              </h3>
              <p className="gameing_para_virtual">
                &#x2022; Instead of sitting on the couch for hours with a
                controller, VR gaming enables you to be physically active while
                experiencing all the fun of traditional games. You may have to
                walk around the room and flap your arms around in order to
                complete and objective, and this helps in burning calories.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h3 className="gameing_title_virtual">Is VR gaming healthy?</h3>
              <p className="gameing_para_virtual">
                VR exercise isn't different from other types of aerobic
                exercise, according to Stanton. You're getting your heart rate
                up, working up a sweat, and burning calories — but it's not as
                monotonous as logging miles on a treadmill. “The best exercise
                is the one with the
              </p>
            </div>
          </div>
          <div className="row  pb-4 mt-0 mb-0">
            <div className="col-lg-6 align-self-center">
              <h3 className="gameing_title_virtual">
                What is mixed reality technology?
              </h3>
              <p className="gameing_para_virtual">
                Mixed reality is a blend of physical and digital worlds,
                unlocking natural and intuitive 3D human, computer, and
                environmental interactions. This new reality is based on
                advancements in computer vision, graphical processing, display
                technologies, input systems, and cloud computing.
              </p>
              <h3 className="gameing_title_virtual">
                What are the benefits of VR in tourism?
              </h3>
              <p className="gameing_para_virtual">
                There is nothing like knowing where you are going before you
                land at a sight. One of the advantages of virtual reality in
                tourism is that tourists can get a 3D tour of their location
                before arriving at the destination.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src="./assets/gameing/technology.png"
                style={{ width: "100%", height: "100%",borderRadius:'9px' }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h3 className="gameing_title_virtual">
                What is traditional tourism?
              </h3>
              <p className="gameing_para_virtual">
                Traditional tourism consists of visiting natural attractions
                with geographic or biological characteristics. Traditional
                tourism includes the visit of tropical forests, rivers, deserts,
                beaches, caves and cliffs
              </p>
            </div>
          </div>
          <div className="row pt-3 mt-0 pb-5 mb-5">
            <div className="col-lg-6 align-self-center order-lg-first mt-4">
              <img
                src="./assets/gameing/sentiment.png"
                style={{ width: "100%", height: "100%",borderRadius:'9px' }}
              />
            </div>
            <div className="col-lg-6 align-self-center order-first">
              <h3 className="gameing_title_virtual">
                Is VR safe for kids under 12?
              </h3>
              <p className="gameing_para_virtual">
                The sentiment that VR is not harmful to kids is also supported
                by the American Academy of Ophthalmology which has stated that
                “Age limitations for VR technology might make sense for content,
                but this technology poses no threat to the eyes.
              </p>
              <h3 className="gameing_title_virtual">Where we are?</h3>
              <img
                src="./assets/gameing/travellogo.png"
                style={{ width: "100%", height: "100%",borderRadius:'9px' }}
              />
            </div>
            <div>
              <h3></h3>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
