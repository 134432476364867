import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export default function Product() {
  const [isactive, setIsActive] = useState("billing");
  // useEffect(() => {}, []);

  const ProductList = (e) => {
    console.log("ele", e.e);
    switch (e.e) {
      case "billing":
        return (
          <NavLink
                to="/Billing"
                style={{ textDecoration: "none" }}>
            <img src="/assets/product/billing_landing.png" style={{ width: "90%" }} />
            <h3 style={{color:"#000"}}>Billing</h3>
            <p  style={{color:"#000"}}>
              2K Billing software simplifies the process of creating and managing
              invoices for businesses. It helps track sales, manage expenses,
              and ensures accurate and efficient billing, saving time and
              reducing errors.
            </p>
          </NavLink>
        );
      case "Stock":
        return (
          <>
              <NavLink
                to="/StockManagement"
                style={{ textDecoration: "none" }}>

            <img src="/assets/product/Stock.png" style={{ width: "90%" }} />
            <h3  style={{color:"#000"}}>Stock Management</h3>
            <p style={{ margin: "0px",color:"#000" }}>
              Detailed item cards, batches, quick stock reports, and accurate
              inventory cost calculations in 2K Inventory
            </p>
            <div className="d-flex">
              &#x2022;&nbsp;
              <p style={{ margin: "0px",color:"#000" }}>
                Keep a record of different product variants and prices
              </p>
            </div>
            <div className="d-flex">
              &#x2022;&nbsp;
              <p style={{ margin: "0px",color:"#000" }}>
                Generate barcodes and print labels
              </p>
            </div>
            <div className="d-flex">
              &#x2022;&nbsp;
              <p style={{ margin: "0px",color:"#000" }}>
                Monitor stock level using detailed reports
              </p>
            </div>
            <div className="d-flex">
              &#x2022;&nbsp;
              <p style={{ margin: "0px",color:"#000" }}>
                Sales and Purchase complete reports
              </p>
            </div>
            <div className="d-flex">
              &#x2022;&nbsp;
              <p style={{ margin: "0px",color:"#000" }}>
credit and Debit detailed reports
              </p>
            </div>
                </NavLink>
          </>
        );
      case "CRM":
        return (
          <>
              <NavLink
                to="/CRM"
                style={{ textDecoration: "none" }}>

            <img src="/assets/product/crm_landing.png" style={{ width: "90%" }} />
            <h3  style={{color:"#000"}}>CRM</h3>
            <p  style={{color:"#000"}} >
              CRM, or Customer Relationship Management, is a strategy for
              managing interactions with customers. It helps businesses build
              and maintain strong relationships by organizing and analyzing
              customer data.
            </p>
                </NavLink>
          </>
        );
      case "Technologies":
        return (
          <>
              <NavLink
                to="/Technology"
                style={{ textDecoration: "none" }}>

            <img src="/assets/product/technolagy.png" style={{ width: "90%" }} />
            <h3  style={{color:"#000"}}>Technologies</h3>
            <p  style={{color:"#000"}}>
              Software technologies are rapidly evolving to meet the demands of
              an ever-changing digital landscape. Cloud computing remains a
              cornerstone, enabling scalable and flexible solutions.
              Additionally, machine learning and artificial intelligence are
              transforming how software processes and analyzes data, unlocking
              new possibilities.
            </p>
                </NavLink>
          </>
        );
      case "Visitor":
        return (
          <>
              <NavLink
                to="/VisitorManagement"
                style={{ textDecoration: "none" }}>

            <img src="/assets/product/visit_landing.png" style={{ width: "90%" }} />
            <h3  style={{color:"#000"}}>Visitor Management</h3>
            <p  style={{color:"#000"}}>
              Visitor management is about keeping track of people who enter and
              leave a place. It helps enhance security and ensures a smooth
              check-in process by efficiently recording visitor information.
            </p>
                </NavLink>
          </>
        );
      case "Treat":
        return (
          <>
          <div>

            <img src="/assets/product/treat.png" style={{ width: "90%" }} />
            <h3  style={{color:"#000"}}>2K Treat</h3>
            <p  style={{color:"#000"}}>
              Our mobile app simplifies travel bookings, making it easy for you
              to plan and reserve your trips with just a few taps. Stay in
              control of your travel arrangements right from your phone,
              ensuring a hassle-free and enjoyable experience.
            </p>
          </div>
          </>
        );
    }
  };
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded("panel1");
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <div className="row mt-5 pt-5 pb-4 websites">

      <div className="col-lg-6 align-self-center align-items-center   ">
        <div style={{ width: "290px",    height: "100%",
    display: 'flex',
    flexDirection: 'column', }}>
          <div
            // className="active"
            className={`${
              isactive == "billing" ? "product_active" : "product_select_card"
            }`}
            onClick={() => setIsActive("billing")}
          >
            <p className="heading">Billing</p>
          </div>
          <div
            className={`${
              isactive == "Stock" ? "product_active" : "product_select_card"
            }`}
            onClick={() => setIsActive("Stock")}
          >
            <p className="heading">Stock Management</p>
          </div>
          {/* <div
            className={`${
              isactive == "Inventory"
                ? "product_active"
                : "product_select_card"
            }`}
            onClick={() => setIsActive("Inventory")}
          >
            <p className="heading"></p>
          </div> */}
          <div
            className={`${
              isactive == "CRM" ? "product_active" : "product_select_card"
            }`}
            onClick={() => setIsActive("CRM")}
          >
            <p className="heading">CRM</p>
          </div>
          {/*  */}
          <div
            className={`${
              isactive == "Visitor" ? "product_active" : "product_select_card"
            }`}
            onClick={() => setIsActive("Visitor")}
          >
            <p className="heading">Visitor Management</p>
          </div>
          <div
            className={`${
              isactive == "Treat" ? "product_active" : "product_select_card"
            }`}
            onClick={() => setIsActive("Treat")}
          >
            <p className="heading">2K Treat</p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 align-self-center align-items-end d-flex justify-content-center">
        <div className="product_card">
          <ProductList e={isactive} />
        </div>
      </div>
      </div>
      <div className="mobile_screen_size">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon='🢃'
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "93%", flexShrink: 0 }}>
          What is Billing Software and we Do it
          </Typography>
    
        </AccordionSummary>
        <AccordionDetails>
        <img src="/assets/product/billing_landing.png" style={{ width: "90%" }} />

        <h3>Billing</h3>
            <p style={{textAlign:'justify'}}>
              2K Billing software simplifies the process of creating and managing
              invoices for businesses. It helps track sales, manage expenses,
              and ensures accurate and efficient billing, saving time and
              reducing errors.
            </p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon='🢃'
          aria-controls="panel1bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "93%", flexShrink: 0 }}>
          What is Stock Managment and we Do it
          </Typography>
    
        </AccordionSummary>
        <AccordionDetails>
        <img src="/assets/product/Stock.png" style={{ width: "90%" }} />

        <h3>Stock Management</h3>
            <p style={{ margin: "0px" ,textAlign:'justify'}}>
              Detailed item cards, batches, quick stock reports, and accurate
              inventory cost calculations
            </p>
            <div className="d-flex">
              &#x2022;&nbsp;
              <p style={{ margin: "0px",textAlign:'justify' }}>
                Keep a record of different product variants and prices
              </p>
            </div>
            <div className="d-flex">
              &#x2022;&nbsp;
              <p style={{ margin: "0px" ,textAlign:'justify'}}>
                Generate barcodes and print labels
              </p>
            </div>
            <div className="d-flex">
              &#x2022;&nbsp;
              <p style={{ margin: "0px" }}>
                Monitor stock level using detailed reports
              </p>
            </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon='🢃'
          aria-controls="panel1bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "93%", flexShrink: 0 }}>
          What is CRM and we Do it
          </Typography>
    
        </AccordionSummary>
        <AccordionDetails>
        <img src="/assets/product/crm_landing.png" style={{ width: "90%" }} />

        <h3>CRM</h3>
            <p style={{textAlign:'justify'}}>
              CRM or Customer Relationship Management, is a strategy for
              managing interactions with customers. It helps businesses build
              and maintain strong relationships by organizing and analyzing
              customer data.
            </p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon='🢃'
          aria-controls="panel1bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: "93%", flexShrink: 0 }}>
          What is Technologies we Do it
          </Typography>
    
        </AccordionSummary>
        <AccordionDetails>
        <img src="/assets/product/technolagy.png" style={{ width: "90%" }} />

        <h3>Technologies</h3>
            <p style={{textAlign:'justify'}}>
              Software technologies are rapidly evolving to meet the demands of
              an ever-changing digital landscape. Cloud computing remains a
              cornerstone, enabling scalable and flexible solutions.
              Additionally, machine learning and artificial intelligence are
              transforming how software processes and analyzes data, unlocking
              new possibilities.
            </p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon='🢃'
          aria-controls="panel1bh-content"
          id="panel5bh-header"
        >
          <Typography sx={{ width: "93%", flexShrink: 0 }}>
          What is Visitor Management we Do it
          </Typography>
    
        </AccordionSummary>
        <AccordionDetails>
        <img src="/assets/product/visit_landing.png" style={{ width: "90%" }} />

        <h3>Visitor Management</h3>
        <p style={{textAlign:'justify'}}>
              Visitor management is about keeping track of people who enter and
              leave a place. It helps enhance security and ensures a smooth
              check-in process by efficiently recording visitor information.
            </p>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon='🢃'
          aria-controls="panel1bh-content"
          id="panel6bh-header"
        >
          <Typography sx={{ width: "93%", flexShrink: 0 }}>
          What is 2K Treat we Do it
          </Typography>
    
        </AccordionSummary>
        <AccordionDetails>
        <img src="/assets/product/treat.png" style={{ width: "90%" }} />
        <h3>2K Treat</h3>
        <p style={{textAlign:'justify'}}>
              Our mobile app simplifies travel bookings, making it easy for you
              to plan and reserve your trips with just a few taps. Stay in
              control of your travel arrangements right from your phone,
              ensuring a hassle-free and enjoyable experience.
            </p>
        </AccordionDetails>
      </Accordion>
 
      </div>
    </>
  );
}
