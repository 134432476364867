import React, { useRef } from "react";
import { Footer, Navbar } from "../components";
import { Controller, useForm } from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";
export default function EduFrom() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
  } = useForm();
  const lang = [
    "Core Java",
    "Spring Boot",
    "Selenium",
    "Testing",
    "React Native",
    "Html",
    "CSS",
    "JavaScript",
    "BootStrap",
    "Angular js",
    "React js",
    "Oracle",
    "MySql",
    "SAP",
    "Tally",
    "MS Office",
  ];
  const location = useLocation();
  const form = useRef();
  const Submit = (data) => {
    console.log(">>>>>log", data);

    // 
    emailjs
      .sendForm("service_jh1j7og", "template_7v0g2od", form.current, 
       "nvT8v1tKsRCEFF4gC"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          reset();
        }
      );
    alert("Email Send succesfully! ");
  };
  return (
    <>

      <section className="education_top_section">
        <div className="container  ">
          <div className="row align-items-center edu_form_he">
            <div className="col-lg-6 img_edu_form">
              <img src="/assets/software.png" style={{ width: "100%" }}  />
            </div>
            <div className="col-lg-5 align-items-center">
              <h2 className="education_title_heading">
                Software Development Residency Program
              </h2>
              <form onSubmit={handleSubmit(Submit)} ref={form}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Name"
                  name="from_names"
                  {...register("from_names")}
                />
                <input
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  name="from_emails"
                  {...register("from_emails")}
                />
                <input
                  className="form-control"
                  type="number"
                  placeholder="Phone Number"
                  name="from_numbers"
                  {...register("from_numbers")}
                />

                <select
                  className="form-control"
                  type="number"
                  placeholder="Select Course "
                  name="courses"
                  {...register("courses")}
                >
                  {lang.map((name) => (
                    <option value={name}>{name}</option>
                  ))}
                </select>

                <input type="submit" className="submit_btn" />
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
